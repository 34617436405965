const tier1 = 250000;
const tier2 = 10000000;

export const calculateAnnualYield = (depositAmount: number) => {
  let apy = getApyByDepositAmount(depositAmount);

  return depositAmount * apy;
}

export const getApyByDepositAmount = (depositAmount: number): number => {
  if (depositAmount < tier1) {
    return 0.035;
  } else if (depositAmount < tier2) {
    return 0.035;
  } else {
    return 0.035;
  }
}

export const getHumanReadableApy = (depositAmount: number): string => {
  if (depositAmount < tier1) {
    return "3.50%";
  } else if (depositAmount < tier2) {
    return "3.50%";
  } else {
    return "3.50%";
  }
}